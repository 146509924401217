import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
import store from "../store"; //

// 公共路由
export const constantRoutes = [
  // 首页
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "NsHome",
    component: () => import("@/views/home/index"),
  },
  // 登录
  {
    path: "/login",
    name: "NsLogin",
    component: () => import("@/views/login/login"),
  },
  {
    path: "/register",
    name: "NsRegister",
    component: () => import("@/views/login/register"),
  },
  {
    path: "/aboutus",
    name: "AboutUs",
    component: () => import("@/views/aboutus/index"),
  },
  {
    path: "/service",
    name: "ExhibitionService",
    component: () => import("@/views/service/index"),
  },
  {
    path: "/activity",
    name: "activity",
    component: () => import("@/views/activity/list"),
  },
  {
    path: "/activityDetail",
    name: "activityDetail",
    component: () => import("@/views/activity/detail"),
  },
  {
    path: "/news",
    name: "News",
    component: () => import("@/views/news/list"),
  },
  {
    path: "/newsDetail",
    name: "NewsDetail",
    component: () => import("@/views/news/detail"),
  },
  {
    path: "/goods",
    name: "Goods",
    component: () => import("@/views/goods/list"),
  },
  {
    path: "/goodsDetail",
    name: "GoodsDetail",
    component: () => import("@/views/goods/detail"),
  },
  {
    path: "/my",
    name: "My",
    redirect: "/my/myaccount",
    component: () => import("@/views/my/index"),
    children: [
      {
        path: "myexhibition",
        name: "MyExhibition",
        component: () => import("@/views/my/myExhibition/index"),
        children: [
          {
            path: "list",
            name: "MyActivityList",
            component: () => import("@/views/my/myExhibition/myActivityList"),
            meta: {
              title: "我的活动列表",
            },
          },
          {
            path: "myActivity",
            name: "MyActivity",
            component: () => import("@/views/my/myExhibition/myActivity"),
            meta: {
              title: "我的活动",
            },
          },
          {
            path: "myActivityDetail",
            name: "MyActivityDetail",
            component: () => import("@/views/my/myExhibition/myActivityDetail"),
            meta: {
              title: "我的报名",
            },
          },

          {
            path: "assignedexh",
            name: "myAssignedexh",
            component: () => import("@/views/my/myExhibition/assignedexh"),
            meta: {
              title: "分配的展位",
            },
          },
          {
            path: "qrcode",
            name: "myQrcode",
            component: () => import("@/views/my/myExhibition/qrcode"),
            meta: {
              title: "二维码凭证",
            },
          },
          {
            path: "lintel",
            name: "myLintel",
            component: () => import("@/views/my/myExhibition/lintel"),
            meta: {
              title: "确认楣板",
            },
          },
        ],
      },
      {
        path: "myaccount",
        name: "MyAccount",
        redirect: "/my/myaccount/myinfo",
        component: () => import("@/views/my/myAccount/index"),
        children: [
          {
            path: "myinfo",
            name: "MyInfo",
            component: () => import("@/views/my/myAccount/myInfo"),
            meta: {
              title: "我的资料",
            },
          },
          {
            path: "mymessage",
            name: "MyMessage",
            component: () => import("@/views/my/myAccount/myMessage"),
            meta: {
              title: "我的信息",
            },
          },
          {
            path: "mymessagedetail",
            name: "MyMessageDetail",
            component: () => import("@/views/my/myAccount/myMessageDetail"),
            meta: {
              title: "我的信息",
            },
          },
          {
            path: "security",
            name: "AccountSecurity",
            component: () => import("@/views/my/myAccount/security"),
            meta: {
              title: "账号安全",
            },
          },
        ],
      },
      {
        path: "onlineexhibition",
        name: "OnlineExhibition",
        redirect: "/my/onlineexhibition/myonlineExhibition",
        component: () => import("@/views/my/onlineExhibition/index"),
        children: [
          {
            path: "myonlineExhibition",
            name: "MyOnlineExhibition",
            component: () =>
              import("@/views/my/onlineExhibition/myOnlineExhibition"),
            meta: {
              title: "我的线上展会",
            },
          },
          {
            path: "uploadgoods",
            name: "UploadGoods",
            component: () => import("@/views/my/onlineExhibition/uploadGoods"),
            meta: {
              title: "上传展品",
            },
          },
        ],
      },
      {
        path: "server",
        name: "Server",
        redirect: "/my/onlineexhibition/server",
        component: () => import("@/views/my/server/index"),
        children: [
          {
            path: "reception",
            name: "Reception",
            component: () => import("@/views/my/server/reception"),
            meta: {
              title: "接待",
            },
          },
          {
            path: "visit",
            name: "Visit",
            component: () => import("@/views/my/server/visit"),
            meta: {
              title: "参观",
            },
          },
        ],
      },
      {
        path: "concurrentactivitys",
        name: "ConcurrentActivitys",
        redirect: "/my/concurrentactivitys/list",
        component: () => import("@/views/my/concurrentActivitys/index"),
        children: [
          {
            path: "list",
            name: "ConcurrentActivitysList",
            component: () => import("@/views/my/concurrentActivitys/list"),
            meta: {
              title: "同期活动",
            },
          },
          {
            path: "mysign",
            name: "MySinConcurrentActivitysList",
            component: () => import("@/views/my/concurrentActivitys/mySign"),
            meta: {
              title: "已报名活动",
            },
          },
        ],
      },
      // 观众中心
      {
        path: "viewer",
        name: "Viewer",
        redirect: "/my/viewer/qrcode",
        component: () => import("@/views/my/viewer/index"),
        children: [
          {
            path: "qrcode",
            name: "myQrcodeForVisiter",
            component: () => import("@/views/my/myExhibition/qrcode"),
            meta: {
              title: "二维码凭证",
            },
          },
        ],
      },
    ],
  },
  // 404
  //   {
  //     path: '/404',
  //     component: () => import('@/views/404'),
  //     hidden: true
  //   },
];
const createRouter = () =>
  new Router({
    mode: "hash",
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
  });

const router = createRouter();
router.beforeEach((to, from, next) => {
  if (to.path.indexOf("/my/") >= 0) {
    store.commit("changePagePath", "/my");
  }
  next();
});
export default router;
