import Vue from 'vue'
import App from './App.vue'
import router from './router' 
import store from './store';
// jq
import $ from 'jquery'
window.$ = $
// element-ui
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)
 
import {_post, _upImg, _upFile, _postForm} from './utils/http'  
 Vue.prototype._post = _post
 Vue.prototype._upImg = _upImg
 Vue.prototype._upFile = _upFile
 Vue.prototype._postForm = _postForm

 import zh_CN from './utils/zh_CN'
 Vue.prototype.language = zh_CN

Vue.config.productionTip = false

Vue.mixin({
  data(){
    return {
      pu_user: {},
    }
  },
  created(){
    this.pc_user = JSON.parse(localStorage.getItem("pc_user"));
  },
  methods:{
    toUrl(url){
      this.$router.push(url)
    }
  }
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
